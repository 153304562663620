@import '../../style/abstract/variables';

@import '../../style/abstract/media';
@import '../../style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';
@import '../../style/abstract/placeholder';

.ProductInformation {
    &-Content {
        p,
        ul li,
        ol li {
            font-weight: normal;
            font-size: 15px;
            line-height: 150%;
            font-family: $font-secondary;
            color: $grey3;
        }

        ul {
            margin-bottom: 14px;
        }

        p:last-child,
        ul li,
        ol li {
            margin: 0;
        }

        img {
            max-width: 100%;
            object-fit: scale-down;
            width: initial;
            height: initial;
        }
    }
}
