@import '../../style/abstract/variables';

@import '../../style/abstract/media';
@import '../../style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';
@import '../../style/abstract/placeholder';

.ProductDetails {
    &-Wrapper {
        padding: 0;
        @include desktop {
            display: flex;
            margin-top: 100px;
        }
    }
    &-Heading {
        font-family: $font-primary;
        font-weight: bold;
        font-size: 22px;
        line-height: 135%;
        letter-spacing: 0.07em;
        color: $blue50;
    }
    &-Usp {
        display: none;

        @include wider-desktop {
            display: block;
            min-width: 195px;
            max-width: 195px;
        }
    }
    &-Content {
        width: 100%;
        @include wider-desktop {
            margin-left: 87px;
            max-width: 1055px;
        }

        .ProductLinks-SliderWrapper {
            border-top: 1px solid $grey5;
            padding: 40px 0;

            @include from-tablet {
                padding: 55px 0;
            }

            .AdovoxSlider_variant_products {
                @include desktop {
                    overflow: visible;
                }
            }
        }
    }
    &-Sections {
        > div:first-of-type .ProductDetails-Section {
            border-top: 0;
        }
    }
    &-Section {
        padding: 40px 0;
        border-top: 1px solid $grey5;

        .ProductDetails-Heading {
            margin-bottom: 40px;
        }

        @include from-tablet {
            padding: 60px 0;
        }
    }
    &-Navigation {
        display: flex;
        align-items: center;
        transition: top 300ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
        position: sticky;
        z-index: 3;
        top: 60px;
        background: $white;
        border-bottom: 2px solid $grey5;

        @include mobileAndTablet {
            overflow-x: scroll;
            align-items: center;
            justify-content: flex-start;
            width: calc(100% + 40px);
            margin: 10px -20px 0;
            font-size: 13px;
            &::-webkit-scrollbar {
                display: none;
                -ms-overflow-style: none;
            }
        }

        @include desktop {
            background: $white;
            top: 147px;
        }
    }

    &-NavigationItem {
        background: $white;
        font-family: $font-secondary;
        font-size: 15px;
        line-height: 150%;
        color: $blue50;
        margin: 0 18px 0 0;

        &:last-child {
            margin-bottom: 0;
        }

        @include mobileAndTablet {
            margin: 0;
        }

        &::before {
            display: none;
        }

        a {
            font-weight: normal;
            border-radius: 7px 7px 0px 0px;
            display: flex;
            align-items: center;
            padding: 12px 35px;
            white-space: nowrap;
            border-bottom: 0;
            &.active {
                color: $white;
                background: $orange50;
            }

            @include mobile {
                padding: 9px 16px;
            }

            @include mobileAndTablet {
                max-height: 40px;
                border-radius: 0;
            }

            @include desktop {
                &:hover {
                    color: $white;
                    background: $orange50;
                }
            }
        }
    }
}
