@import '../../style/abstract/variables';

@import '../../style/abstract/media';
@import '../../style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';
@import '../../style/abstract/placeholder';

:root {
    --daily-promo-title-font: #{$font-primary};
    --daily-promo-title-background: #{$default-primary-base-color};
    --daily-promo-title-color: #{$white};
    --daily-promo-content-background: #{$default-secondary-base-color};
    --daily-promo-name-font: #{$font-secondary};
    --daily-promo-name-color: #{$white};
    --daily-promo-price-font: #{$font-secondary};
    --daily-promo-price-old-color: #{$orange30};
    --daily-promo-price-promo-color: #{$white};
    --daily-promo-counter-tile-background: #{$default-primary-base-color};
    --daily-promo-counter-tile-color: #{$white};
    --daily-promo-counter-text-color: #{$orange30};
    --daily-promo-counter-text-font: #{$font-secondary};
    --daily-promo-cta-color: #{$default-secondary-base-color};
    --daily-promo-cta-background: #{$white};
    --daily-promo-cta-hover-background: #{$default-primary-base-color};
    --daily-promo-cta-hover-border: #{$default-primary-base-color};
    --daily-promo-cta-hover-color: #{$white};
    --daily-promo-img-background: #{$white};
    --daily-promo-max-width: 534px;
}

.DailyPromoProduct {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    @include from-tablet {
        align-items: flex-start;
    }
    @include desktop {
        max-width: var(--daily-promo-max-width);
        min-width: var(--daily-promo-max-width);
    }
    &-Title {
        background: var(--daily-promo-title-background);
        border-radius: 5px 5px 0 0;
        font-family: var(--daily-promo-title-font);
        font-weight: bold;
        font-size: 22px;
        line-height: 136%;
        text-align: center;
        letter-spacing: 1px;
        color: var(--daily-promo-title-color);
        width: 100%;
        max-width: 242px;
        padding: 10.5px;
        @include from-tablet {
            margin-left: 22px;
            max-width: 232px;
        }
    }
    &-Content {
        background: var(--daily-promo-content-background);
        border-radius: 10px;
        padding: 24px 32px 32px;
        width: 100%;
        @include mobile {
            max-width: 320px;
        }
        @include from-tablet {
            display: flex;
            padding: 22px;
        }
        @include from-tablet {
            max-width: var(--daily-promo-max-width);
            min-width: var(--daily-promo-max-width);
        }
    }
    &-ImgWrapper {
        background: var(--daily-promo-img-background);
        border-radius: 10px;
        margin-bottom: 30px;
        @include from-tablet {
            min-width: 232px;
            min-height: 315px;
            margin: 0 36px 0 0;
        }
        a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
        }
        img {
            max-width: 100%;
            display: block;
            padding: 10px;
            object-fit: contain;
        }
    }
    &-PriceWrapper {
        margin-bottom: 20px;
        @include mobile {
            display: flex;
            align-items: baseline;
        }
        @include from-tablet {
            margin-top: 10px;
        }
    }
    &-Price {
        font-family: var(--daily-promo-price-font);
        font-weight: bold;
        letter-spacing: -0.2px;
        color: var(--daily-promo-price-promo-color);
        @include from-tablet {
            display: block;
        }
    }
    &-Price_oldPrice {
        color: var(--daily-promo-price-old-color);
        font-size: 18px;
        line-height: 144%;
        text-decoration-line: line-through;
        @include mobile {
            font-size: 16px;
            line-height: 72%;
        }
    }
    &-Price_promoPrice {
        font-size: 36px;
        line-height: 72%;
        @include mobile {
            order: -1;
            margin-right: 15px;
            font-size: 30px;
            line-height: 87%;
        }
        @include from-tablet {
            margin: 15px 0 30px;
        }
    }
    &-Name {
        font-family: var(--daily-promo-name-font);
        font-weight: normal;
        font-size: 15px;
        line-height: 150%;
        color: var(--daily-promo-name-color);
        &:hover {
            color: var(--daily-promo-name-color);
        }
    }
    &-AddToCart {
        --button-height: 40px;
        --button-hover-height: 40px;
        background-color: var(--daily-promo-cta-background);
        border-color: var(--daily-promo-cta-background);
        color: var(--daily-promo-cta-color);
        font-size: 14px;
        font-weight: normal;
        line-height: 157%;
        width: 100%;
        max-width: 256px;
        svg {
            fill: var(--daily-promo-cta-color);
            path {
                stroke: var(--daily-promo-cta-color);
            }
        }
        @include from-tablet {
            max-width: 209px;
        }
        @include desktop {
            min-width: 209px;
        }
        &.Button:not([disabled]):hover,
        &.Button:not([disabled]):focus {
            background-color: var(--daily-promo-cta-hover-background);
            border-color: var(--daily-promo-cta-hover-border);
            color: var(--daily-promo-cta-hover-color);
            svg {
                fill: var(--daily-promo-cta-hover-color);
            }
            svg path {
                stroke: var(--daily-promo-cta-hover-color);
            }
        }

        &.Button:disabled {
            svg path {
                stroke: var(--button-disabled-color);
                fill: var(--button-disabled-color);
            }
        }
    }
    &-Countdown {
        margin: 30px 0 25px;
    }
    &-CountdownTitle {
        color: var(--daily-promo-counter-text-color);
        font-weight: normal;
        font-family: $font-secondary;
        font-size: 13px;
        line-height: 150%;
        margin-bottom: 10px;
    }
    &-CountdownTimer {
        display: flex;
        align-items: baseline;
        > span {
            margin: 0 5px;
            font-weight: bold;
            font-size: 20px;
            font-family: var(--daily-promo-counter-text-font);
            line-height: 150%;
            color: var(--daily-promo-counter-text-color);
        }
    }
    &-CountdownItem {
        text-align: center;
        color: var(--daily-promo-counter-text-color);
        font-weight: normal;
        font-size: 13px;
        line-height: 150%;
        font-family: var(--daily-promo-counter-text-font);
        > div {
            color: var(--daily-promo-counter-tile-color);
            background-color: var(--daily-promo-counter-tile-background);
            padding: 5px 0;
            border-radius: 5px;
            font-weight: bold;
            font-size: 20px;
            line-height: 150%;
            font-family: var(--daily-promo-counter-tile-font);
            width: 32px;
            text-align: center;
            margin-bottom: 5px;
        }
    }
    &-Placeholder {
        width: 100%;
        @include placeholder(400px);
        @include desktop {
            position: absolute;
            z-index: 2;
            @include placeholder(900px);
        }
    }
}
