@import '../../style/abstract/variables';

@import '../../style/abstract/media';
@import '../../style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';
@import '../../style/abstract/placeholder';

[dir='ltr'] .ProductCard {
    --product-card-background: #{$white};
    --product-card-wishlist-button-background: transparent;
    --product-card-wishlist-button-color: #d8d5d5;
    --product-card-compare-button-background: transparent;
    --product-card-compare-button-color: var(--product-card-wishlist-button-color);
    --product-card-icon-background: #{$white};
    --product-card-icon-size: 36px;
    --compare-button-size: var(--product-card-icon-size);
    --wishlist-heart-size: var(--product-card-icon-size);
    --product-cart-border-radius: 10px;
    --product-cart-name-color: #{$black};
    border-radius: var(--product-cart-border-radius);
    transition: box-shadow 0.2s ease-in-out;

    &:last-child {
        margin-bottom: 0;
    }

    &:hover {
        box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.15);
        @include desktop {
            box-shadow: 0 10px 25px 0 rgba(230, 120, 120, 0.15);
        }

        .ProductCard-RelatedProductList {
            display: flex;
        }
    }

    &-Link {
        margin: 0;
        position: relative;
        z-index: 1;

        &:after {
            content: '';
            width: 1px;
            height: calc(100% - 40px);
            position: absolute;
            top: 20px;
            bottom: 20px;
            right: -1px;
            background: rgba(107, 69, 188, 0.1);
        }
    }

    &-Content {
        border-radius: 0 0 var(--product-cart-border-radius) var(--product-cart-border-radius);
    }

    &-FigureReview {
        border-radius: var(--product-cart-border-radius) var(--product-cart-border-radius) 0 0;
        padding: 0 15px;
    }

    &-Picture {
        .Image-Image {
            left: 50%;
            transform: translateX(-50%);
            max-width: 244px;
        }
    }

    &-PriceWrapper,
    &-Name,
    &-Actions {
        padding: 0 20px;
        @include wide-desktop {
            padding: 0 8px;
        }
    }

    &-Name {
        line-height: 150%;
        font-size: 13px;
        font-family: $font-secondary;
        color: var(--product-cart-name-color);
        white-space: initial;
        margin: 5px 0 15px;
        height: 38px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    &-ProductActions {
        top: 20px;
        left: 10px;
        position: absolute;
        flex-direction: column;
        margin-left: 0;
        z-index: 2;

        > div {
            margin-bottom: 12px;
        }

        button {
            box-shadow: none;
            border-radius: 100%;
            background: var(--product-card-icon-background);
            width: var(--product-card-icon-size);

            &:hover,
            &:focus {
                border-radius: 100%;
            }
        }
    }

    &-Reviews,
    &-Brand {
        display: none;
    }

    .ProductLabel {
        z-index: 2;
    }

    &-RelatedProductWrapper {
        height: 0;
        position: relative;
    }

    &-RelatedProductList {
        display: none;
        justify-content: flex-start;
        align-items: center;
        padding: 0 15px;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);

        @include narrow-desktop {
            padding: 0 30px;
        }
    }

    &-RelatedProduct {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        cursor: pointer;
        border: 1px solid $grey5;
        border-radius: 5px;
        overflow: hidden;
        background: $white;

        img {
            object-fit: cover;
        }

        &:not(:last-of-type) {
            margin-right: 5px;
        }

        &:hover {
            border-color: $orange50;
        }

        &_isNumber {
            background-color: $grey5;
        }
    }
}
