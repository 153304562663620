@import '../../style/abstract/variables';

@import '../../style/abstract/media';
@import '../../style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';
@import '../../style/abstract/placeholder';

:root {
    --product-configurable-attributes-title-color: #{$default-primary-base-color};
}

.ProductConfigurableAttributes {
    &-Title {
        font-family: $font-secondary;
        font-size: 13px;
        line-height: 20px;
        font-weight: 400;
        color: var(--product-configurable-attributes-title-color);
        margin-block: 24px 10px;
    }
}
