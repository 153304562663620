@import '../../style/abstract/variables';

@import '../../style/abstract/media';
@import '../../style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';
@import '../../style/abstract/placeholder';

.ProductReviewForm {
    &-Wrapper {
        padding: 20px 0;
        @include desktop {
            display: block;
        }
    }

    &-Rating {
        display: flex;
        direction: ltr;
        align-items: center;
    }

    &-Legend {
        padding: 0;
        font-size: 15px;
        color: $blue50;
        margin-right: 30px;
    }

    &-Stars {
        direction: rtl;

        input[type='radio'] {
            border: none !important;
        }
    }

    &-Button {
        font-size: 15px;
        padding: 0 30px;
        width: 100%;

        @include desktop {
            max-width: 263px;
        }
    }
}
