@import '../../style/abstract/variables';

@import '../../style/abstract/media';
@import '../../style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';
@import '../../style/abstract/placeholder';

.ProductWishlistButton {
    svg {
        width: 20px;
        path {
            stroke: $default-secondary-base-color;
        }
    }

    .ProductWishlistButton-Button_isInWishlist.Button,
    .Button:hover {
        background: $default-secondary-base-color;
        svg path {
            stroke: $white;
        }
    }
}
