@import '../../style/abstract/variables';

@import '../../style/abstract/media';
@import '../../style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';
@import '../../style/abstract/placeholder';

.ProductReviewList {
    margin: 0;
    display: block;

    @include wider-desktop {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 84px;
        grid-row-gap: 0;
    }
}
